<template>
  <div class="about">
    <h1>This is an about page HERE</h1>
  </div>
</template>

<script>
// import { getAuth } from "firebase/auth";
// const auth = getAuth()
// console.log(auth)

//   console.log("this")
// export default {
//     name: 'about',
//     data() {
//         return {}
//     }
// }
</script>